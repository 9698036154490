.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.NegativeAmount {
  color: red!important;
}

.OverDue {
  background-color: gold;
}

#selectedRegion {
  position: absolute;
  top: 130px; left: 445px;
  width: 120px;
  height: 110px;
  background: lightgreen;
  z-index: 5;
  display: none;
  opacity: 50%;
  border-radius: 1cap;
  border: 4px solid darkgreen;
  pointer-events: none;
}

#balloon {
  position: absolute;
  top: 300px; left: 400px;
  width: 100px;
  margin: 0 auto 0;
  padding: 5px;
  background: yellowgreen;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
  text-align: center;
  border-radius: 8px;
  z-index: 10;
  display: none;
}

#balloon::before {
  content: '';
  position: absolute;
  left: -10px;
  bottom: 20px;
/*
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
*/
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background: yellowgreen;
/*  box-shadow: 3px 3px 3px rgba(0,0,0,0.3);*/
  z-index: 10;
}
